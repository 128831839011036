import React from 'react'
import { Container } from '~ux'

const Agreement = () => {
  return (
    <div className="bg-[#f9f9f8] pt-60 pb-110">
      <Container className="text-14 leading-28 text-purple/60">
        <h1 className="text-purple">Согласие на обработку персональных данных </h1>
        <br />
        Заполняя настоящую форму на сайте{' '}
        <a href="honeyleads.ru" className="text-[#FF7143]">
          honeyleads.ru
        </a>{' '}
        (далее – «Сайт»), я подтверждаю, что:
        <ul className="list-[square] pl-32">
          <li className="pl-10">
            указанные при регистрации/направлении формы на Сайте персональные данные принадлежат лично мне;{' '}
          </li>
          <li className="pl-10">
            вся информация о моей личности, изложенная при регистрации/направлении формы на Сайте, является достоверной
            и полной;{' '}
          </li>
          <li className="pl-10">
            я внимательно и в полном объеме ознакомился с настоящим согласием и содержащимися в нем условиями обработки
            моих персональных данных;{' '}
          </li>
          <li className="pl-10">
            все положения настоящего согласия и условия обработки моих персональных данных мне понятны и принимаются
            мною без каких-либо оговорок и ограничений.{' '}
          </li>
        </ul>
        <br />Я даю согласие ООО «Ханилидс» (ИНН 9710082903 КПП 771001001 ОГРН 1207700149414), расположенному по адресу:
        125047, город Москва, Брестская 1-я улица, дом 35, помещение 501, на автоматизированную и неавтоматизированную
        обработку моих персональных данных с совершением следующих действий: сбор, запись, систематизация, накопление,
        хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ),
        блокирование, удаление и уничтожение персональных данных с целью:
        <ul className="list-[square] pl-32">
          <li className="pl-10">запроса демонстрации работы продуктов ООО «Ханилидс»; </li>
          <li className="pl-10">получения коммерческих предложений от ООО «Ханилидс». </li>
        </ul>
        <br />
        <h2 className="text-purple">Перечень персональных данных, в отношении которых дается согласие:</h2>
        <br />
        <ul className="list-[square] pl-32">
          <li className="pl-10">фамилия, имя, отчество; </li>
          <li className="pl-10">адрес электронной почты; </li>
          <li className="pl-10">контактный номер телефона; </li>
          <li className="pl-10">и иные данные, предоставленные мной самостоятельно. </li>
        </ul>
        <br />
        Согласие предоставляется на срок 5 (пять) лет с момента регистрации на Сайте.
        <br />
        Согласие может быть отозвано мной путем направления письменного обращения в ООО «Ханилидс» по электронному
        адресу:{' '}
        <a href="mailto:hi@honeyleads.com" className="text-[#FF7143]">
          hi@honeyleads.com
        </a>
      </Container>
    </div>
  )
}

export default Agreement
